import React from 'react';
import SideBar from '../Sidebar';
import "./style.css"
import Footer from '../Footer/Footer';
import { Button, TablePagination } from '@material-ui/core';
import {Dialog, DialogContent, DialogContentText, DialogTitle, Box, IconButton } from '@mui/material';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link, withRouter } from 'react-router-dom';
import API from '../../Api';
// React Notification
import { NotificationManager } from 'react-notifications';
import 'reactjs-crontab/dist/index.css';
import { columnHeader } from './data';
import moment from 'moment';
import { searchItems, claimsDropdown } from '../Footer/data';
import _ from 'lodash';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import refresh from '../../assets/icons/Refresh.svg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import ruleoutLogo from '../../assets/icons/logo2.svg';
import { LoadingSpinner } from 'lib/loader';
import { getDataFromSession } from '../helper/helper'
import { RoCheckbox } from 'layouts/Form/Checkbox';
// import jwt_decode from 'jwt-decode';
// import io from "socket.io-client";
import {socketInstance, getClientKey} from '../../socket/connection';

let socket = socketInstance();

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM');

let myCurrentDate=new Date();
let myPastDate=new Date(myCurrentDate);
myPastDate.setDate(myPastDate.getDate() - 60);
let maxPastDate = new Date(myCurrentDate);
maxPastDate.setDate(maxPastDate.getDate() - 365)

let currentToDate = new Date(Date.now());

let rulesTimer;
let client = JSON.parse(sessionStorage.getItem("clientDetail"));

class RuleProcessor extends React.Component {
    autoRefresh;
    constructor(props) {
        super(props);
        const eachPageContaiRow = 50;
        this.SESSION_PRACTICE_KEY = 'practice'
        this.state = {
            refreshPractice: () => {},
            encounters: [],
            encounterData: [],
            newEncounters: [],
            selectedValues: [],
            showRule: false,
            isLoading: false,
            getLastRule: [],
            chargeData: [],
            date: null,
            localDate: null,
            rulesRan: false,
            trizettoData: [],
            eligibilityData: [],
            ruleDate: null,
            formattedRuleDate: null,
            rulesData: [],
            sortData: false,
            showPopup: false,
            showCancelPopup: false,
            showRulesTryAgain: false,
            showChargesPopup: false,
            showRunningCharges: false,
            showCancelRunningCharges: false,
            showChargesTryAgain: false,
            showLoader: false,
            showCancelLoader: false,
            selectDateType: 'createdDate',
            fromDate: null,
            toDate: null,
            fromDate1: null,
            toDate1: null,
            filterDateType: '',
            selectedColumn: null,
            encounterCount: null,

            checkboxError: '',

            checkedItems: {},
            count: 0,
            message: null,
            ruleSuccess: '',
            searchKey: '',
            searchValue: '',
            trizettoSuccess: false,
            practiceId: '',
            practiceId1: JSON.parse(sessionStorage.getItem('practice'))?.id,
            pmLinkedStatus: JSON.parse(sessionStorage.getItem('practice'))?.PMLinkedStatus,
            optionSelector: false,
            draftPlaceholder: '',
            encounterLength: null,
            claimSelector: false,
            labelValue: '',
            claimKey: '',
            autoRunRuleChecked : false,
            claimsDropdownSelect: '',
            totalRows: 0,
            startClaimsLimit: 0,
            endClaimsLimit: eachPageContaiRow,
            page: 0,
            pages: [eachPageContaiRow, 100, 200],
            rowsPerPage: eachPageContaiRow,
            enableAutoRunRuleSetting: false,
            count_encounters: 0,
            providerType: '',
            showProviderPopUp: false,
            handlePracticeChangeByNotReviewPage : () => {}
        }
    }

    handleAutoRunStatus = (autoRunRuleValue) => {
        if(socket) {
            // let autoRunRuleValue = JSON.parse(sessionStorage.getItem("practice"))
            socket.emit("checkAutoRun", {
                practiceId: autoRunRuleValue,
            });

            socket.on("checkAutoRun", (data) => {
                if(data.status === true) {
                    this.setState({enableAutoRunRuleSetting:true})
                } else {
                    this.setState({enableAutoRunRuleSetting:false})
                }
            });
        }
    }

    handleRuleProcesserButton = async () => {
        if(this.state.ruleSuccess === true){
            this.setState({ 
                ruleSuccess: false,
                searchValue: '',
                claimsDropdownSelect: 'notRan',
                labelValue: 'Claim Center - Not Ran'
            });
            return this.getEncounters(this.state.practiceId1);
        }
    }

    handlePaginationData = (e, newPage) => {
        this.setState({ page: newPage });
        if (this.state.page < newPage) {
            this.setState({
                startClaimsLimit: this.state.startClaimsLimit + this.state.rowsPerPage
            }, async () => {
                this.searchEncounters();
            })
        } else {
            this.setState({
                startClaimsLimit: this.state.startClaimsLimit - this.state.rowsPerPage
            }, async () => {
                this.searchEncounters();
            })
        }
    }

    onRowsPerPage = async (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value),
            page: 0,
            startClaimsLimit: 0,
            endClaimsLimit: event.target.value
        }, async () => await this.searchEncounters())
    }

    // function to perform auto run rule
    getAutoRunRulesStatus = (AutoRunRulesStatus) => {
        this.setState({autoRunRuleChecked: AutoRunRulesStatus})
    }

    handleClaimsDropdown = async (event) => {
        this.setState({
            claimsDropdownSelect: event.target.value,
            startClaimsLimit: 0,
            page: 0
        });
        this.searchEncounters()
    }

    // function to sort columns
    onSort = (event, sortKey, type, ruleSuccess) => {
        const {encounters} = this.state;
        var rulesData = this.state.rulesData;
        if(type === 'asc'){
            if(ruleSuccess == true){
                rulesData.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
               
            } else {
                encounters.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
            }
            this.setState({
                ...this.state,
                typeState: 'desc',
                sortData: !this.state.sortData
        });
        }else if(type === 'desc'){
            if(ruleSuccess == true){
                rulesData.sort((a,b) => b[sortKey].localeCompare(a[sortKey]))
            } else {
                encounters.sort((a,b) => b[sortKey].localeCompare(a[sortKey]))
            }
            this.setState({
                ...this.state,
                typeState: 'asc',
                sortData: !this.state.sortData
        });
        }
        this.setState({encounters});
    }

    // function to change color of heading names when clicked
    colorElement = (index) => {
        if(index === this.state.selectedColumn){
            this.setState({selectedColumn: null});
        }else{
            this.setState({selectedColumn: index});
        }
    }

    // function to show div
    handleShow = (event) => {
        this.setState({ showRule: !this.state.showRule });
    }
    // function to hide div
    handleHide = (event) => {
        this.setState({ showRule: false })
    }

    // function to handle date change
    handleDateChange = (event, _date) => {
        const formattedDate = moment(event).format('YYYY-MM-DD');
        this.setState({
            formattedRuleDate: event,
            labelValue : 'Claim Center - Ran by Day'
        });
    }

    // function to get run rules
    getRunRules = (date) => {
        this.setState({rulesData: [], trizettoData: [], showLoader: true});
        API.get('getRunRules', {params: {date: date}}).then((response) => {
            let filteredData = response.data;
            let rulesData = filteredData.chargeDetail;
            let trizettoData = filteredData.eligibilityDetail;
            let ruleTrizettoData = filteredData.runRuleDetail;
   
            ruleTrizettoData.sort(function(a, b){
                return new Date(b.currentTime) - new Date(a.currentTime);
            })
            this.setState({
                rulesData: rulesData ? rulesData : [], 
                trizettoData: ruleTrizettoData,
                eligibilityData: trizettoData,
                ruleSuccess: response.data.success,
                showLoader: false
                });

        }).catch(err => {
          console.error(err);
        })
    }

    // function to handle cell redirect
    handleCell = (id, linkedStatus) => {
        const linkedData = this.state.encounters.filter(f => f.EncounterID === id).map(item => {
            const obj = {}
            obj["pName"] = item.payerName;
            obj["pId"] = item.payerId;
            obj["linkedPayer"] = item.PrimaryInsurancePlanName;
            obj["linkedPayerId"] = item.PrimaryInsurancePolicyPlanID;
            
            return obj;
        })
        let body_data = {
            rulesRunStatus: this.state.ruleSuccess ? this.state.ruleSuccess : false
        }
        API.post(`getChargesFromLocalId/${id}`, body_data).then((response) => {
            const chargeData = response.data;
            if(this.state.ruleSuccess !== true){
                this.props.history.push({
                    pathname: `/insurances/${id}/${chargeData[0]?.PrimaryInsurancePolicyPlanID}/Primary`, 
                    state: {
                        chargeData: chargeData, 
                        linkedData: linkedData,
                        insuranceType: localStorage.getItem("isPrimary") === "true" ? "Primary" : "Secondary",
                        PrimaryInsurancePlanName: chargeData[0]?.PrimaryInsurancePlanName
                    }});
            }else{
                this.props.history.push({pathname: `/insurances/`, state: {chargeData: chargeData.chargeDetails, linkedData: linkedData}});
            }
    }).catch(err => {
        console.error(err);
    });
    }
    // Function to add our give data into cache
    addDataIntoCache = (cacheName, url, response) => {
        const data = new Response(JSON.stringify(response));
        if ('caches' in window) {
            caches.open(cacheName).then((cache) => {
                cache.put(url, data);
            });
        }
    }

    handleRow = (id, encounterProcedureId) => {
        let body_data = {
            rulesRunStatus: this.state.ruleSuccess ? this.state.ruleSuccess : false,
            encounterProcedureId: encounterProcedureId
        }
        API.post(`getChargesFromLocalId/${id}`, body_data).then((response) => {
            const chargeData = response.data;
            if(this.state.ruleSuccess !== true) {
                sessionStorage.setItem('viewedDetail', '0' ) // set 0 for not viewed page and log of action will trigger
                this.props.history.push({ pathname: '/inspection-notran', state: {allChargeData: chargeData} });
            }else{
                let length = this.state.trizettoData.length;
                let chargeInstanceIds = [];
                let eligibilityRes = this.state.eligibilityData.filter(item => item.instanceID === id);
                localStorage.setItem('isReviewd',  eligibilityRes[0].isReviewed);
                sessionStorage.setItem('viewedDetail', '0' ) // set 0 for not viewed page and log of action will trigger
                this.addDataIntoCache('navigateBack', 'goReverse', {
                    goReverse: true,
                    from: 'inspection-noviolation',
                    search_value: this.state.searchValue,
                    searchKey: this.state.searchKey,
                    claimsDropdownSelect: this.state.claimsDropdownSelect,
                    formattedRuleDate: this.state.formattedRuleDate,
                    endClaimsLimit: this.state.endClaimsLimit,
                    startClaimsLimit: this.state.startClaimsLimit,
                    page: this.state.page
                })
                this.props.history.push({ 
                    pathname: '/inspection-noviolation', 
                    state: {
                        chargeData: chargeData.chargeDetails[0],
                        allChargeData: chargeData.chargeDetails,
                        trizettoData: chargeData.runRuleDetails, 
                        eligibilityData: eligibilityRes,
                        trizettoDate: this.state.trizettoData[length-1],
                        from: 'rule-processor',
                        rulesRanBy: chargeData.ruleRanBy,
                        isBypassed: chargeData.isBypassed
                    } 
                });
            }
            this.setState({ chargeData: chargeData });
        }).catch(err => {
            console.error(err);
        });
    }

    // function to handle single select checkbox
    handleSingleSelect = (event) => {
        const { selectedValues } = this.state;
        const selectedIndex = event.target.value;
        if (!selectedValues.includes(selectedIndex)) {
            let checkedCount = selectedValues.length;
            this.setState({ selectedValues: [...selectedValues, selectedIndex] });
            if(checkedCount === 29){
                this.setState({message: NotificationManager.info('30 encounters have already been selected', 'Message!', 5000)})
            }
        } else {
            this.setState({
                selectedValues: selectedValues.filter((index) => {
                    return index !== selectedIndex;
                })
            })
        }
    }

    // function to handle check all rows
    handleCheckAll = () => {
        const { selectedValues, encounters } = this.state;
        if (selectedValues.length < encounters.length) {
            this.setState({
                selectedValues: encounters.map(x => x.EncounterID)
            });
        } else {
            this.setState({ selectedValues: [] });
        }
    }

    handlePMConnection = () => {
        this.setState({
            pmLinkedStatus: JSON.parse(sessionStorage.getItem('practice'))?.PMLinkedStatus
        })
    }

    // function to get encounters
    getEncounters = (practiceId) => {
        this.setState({ isLoading: true });
        let practiceData  = JSON.parse(sessionStorage.getItem("practice"));
        let client = JSON.parse(sessionStorage.getItem("clientDetail"));
        practiceId = practiceData.id;
        LoadingSpinner.show()
        API.get(`getChargesFromLocal/${practiceId}/Draft`).then((response) => {
            if (response.data.result.status === 400) {
                this.setState({
                    localDate: response.data.result.date,
                    filterDateType: response.data.result.filterDateType,
                    fromDate1: response.data.result.fromDate,
                    toDate1: response.data.result.toDate,
                })
            } else {
                if(response.data.result.result.length > 0){
                }else{
                    NotificationManager.error('No records found for the date range!', 'Failure!', 3000);
                }
                const encounters = response.data.result;
                if (encounters.result.length === 0) {
                    this.setState({ 
                        encounters: [],
                        ruleSuccess: null,
                        localDate: response.data.result.date,
                        filterDateType: response.data.result.filterDateType,
                        fromDate1: response.data.result.fromDate,
                        toDate1: response.data.result.toDate,
                    });
                } else {
                    this.setState({  
                        encounters: encounters.result,
                        encounterCount: encounters.count,
                        isLoading: false, 
                        localDate: encounters.result[0].date,
                        filterDateType: encounters.result[0].filterDateType,
                        fromDate1: encounters.result[0].fromDate,
                        toDate1: encounters.result[0].toDate,
                        practiceId1: this.state.practiceId1,
                        ruleSuccess: false,
                        providerType: encounters.providerType ? encounters.providerType : client.providerType
                    });
                }
            }
            LoadingSpinner.hide()
        }).catch(err => {
            console.error(err);
            LoadingSpinner.hide()
        })
    }
    
    getCharges = (practiceId) => {
        let {selectDateType, fromDate, toDate} = this.state;
        this.setState({showChargesPopup: false, showRunningCharges: !this.state.showRunningCharges, showCancelRunningCharges: false});
        let chargesTimer = setTimeout(() => this.setState({showCancelRunningCharges: !this.state.showCancelRunningCharges}), 15000)
        localStorage.setItem("refreshState", true);
        let practiceData  = JSON.parse(sessionStorage.getItem("practice"))
        let body_data = {
            practiceId: practiceData.id,
            dateType: selectDateType,
            fromDate: fromDate ? (new Date(fromDate)).toLocaleDateString('en-US') : (new Date(myPastDate)).toLocaleDateString('en-US'),
            toDate: toDate ? (new Date(toDate)).toLocaleDateString('en-US') : (new Date(currentToDate)).toLocaleDateString('en-US'),
            userToken: JSON.parse(sessionStorage.getItem("token")).token,
            lastUpdatedDate: Date.now()
        }
        API.post('kareo/getCharges', body_data, {
            headers: {
                CustomerKey: practiceData.key,
                User: practiceData.email,
                Password: practiceData.password,
                PracticeName: practiceData.practiceName
            }
        }).then((response) => {
            if(this.state.pmLinkedStatus === false){
                this.setState({
                    autoRunRuleChecked: false,
                    showChargesTryAgain: true, 
                    showRunningCharges: false, 
                    showCancelRunningCharges: false
                });
            }
            else {
                if(response.data.result.ruleProcessor.length > 0){
                    clearTimeout(chargesTimer);
                    NotificationManager.success('Data successfully retrieved!', 'Successful!', 9000);
                    setTimeout(() => window.location.reload(), 2000)
                }else{
                    NotificationManager.error('No records found for the date range!', 'Failure!', 5000);
                    this.setState({
                        selectDateType: 'createdDate',
                        fromDate: myPastDate,
                        toDate: currentToDate
                    })
                }
                localStorage.setItem("refreshState", false);
                const encounters = response.data.result;
                let draftArray = []; let draftEncounters;
                if(encounters.ruleProcessor.length === 0){
                    draftEncounters = []
                }else{
                    encounters.ruleProcessor.forEach((encounter, i) => {
                        draftArray.push(encounter.getChargeData);
                    })
                    draftEncounters = draftArray.filter(item => item.EncounterStatus === 'Draft');
                }
                this.setState(prevState => ({ 
                    showRunningCharges: false,
                    encounters: draftEncounters,
                    isLoading: false, 
                    date: response.data.date,
                    practiceId: draftEncounters.length > 0 ? draftEncounters[0].PracticeID : '',
                    filterDateType: encounters.filterDateType,
                    fromDate1: encounters.fromDate,
                    toDate1: encounters.toDate
                }));
            }
        }).catch(err => {
            NotificationManager.error('Data cannot be fetched', 'Error!', 5000);
            this.setState({
                showChargesTryAgain: true, 
                showRunningCharges: false, 
                showCancelRunningCharges: false
            });
            console.error(err);
        })
        // }
    }

    // function to handle charges pop up
    OpenChargesPopup = () => {
        this.setState({showChargesPopup: true})
    }
    closeChargesPopup = () => {
        this.setState({showChargesPopup: false, fromDate: myPastDate, toDate: new Date()});
    }

    // function to handle charges dropdown
    handleChargesDropdown = (e) => {
        const {value} = e.target;
        this.setState({selectDateType: value});
    }

    // function to handle charges date change
    handleFromDate = (e) => {
        this.setState({fromDate: e});
    }
    handleToDate = (e) => {
        this.setState({toDate: e});
    }

    // function to validate date difference
    isDateValid = () => {
        const {fromDate, toDate} = this.state;

        let formattedFromDate = (new Date(fromDate ? fromDate : myPastDate)).toLocaleDateString('en-US');
        let formattedToDate = (new Date(toDate ? toDate : currentToDate)).toLocaleDateString('en-US');

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const validFromdate = new Date(formattedFromDate);
        const validToDate = new Date(formattedToDate);

        const diffDays = Math.round(Math.abs((validFromdate - validToDate) / oneDay));
        if(diffDays > 60){
            NotificationManager.error('Date range exceeded 60 days', 'Error!', 5000);
        }else {
            this.getCharges();
        }
    }

    // logic to search encounters by dropdown selection
    handleSelectChange = e => {
        let value = searchItems.filter(item => {
            return item.key === e.target.value
        })
        if(this.state.searchValue !== ''){
            this.searchEncounters()
        }
        this.setState({
            searchKey: value ? value[0].key : 'primaryInsurance', 
            searchValue: '',
            optionSelector: false,
            draftPlaceholder: value[0].value
        });
    }

    handleInputChange = (event) => {
        this.setState({searchValue: event.target.value, startClaimsLimit: 0, page: 0 }, () => {
            this.searchEncounters(this.state.searchKey ? this.state.searchKey : 'primaryInsurance', event.target.value);
        });
    }

    selectHandler = (e) => {
        this.setState({optionSelector: !this.state.optionSelector})
    }

    handleClaimChange = (e) => {
        let value = claimsDropdown.filter(item => {
            return item.key === e.target.value
        })
        this.setState({
            claimKey: value ? value[0].key : 'PrimaryInsurancePlanName', 
            claimSelector: false,
            labelValue: value[0].value
        })
    }

    claimHandler = (e) => {
        this.setState({claimSelector: !this.state.claimSelector})
    }

    searchEncounters = _.debounce(() => {
        this.setState({encounters: [], showLoader: this.state.claimsDropdownSelect !== 'ranByDay' ? true : false});
        if(this.state.enableAutoRunRuleSetting === false){
        if(this.state.searchValue !== '' || this.state.claimsDropdownSelect){
            if(this.state.claimsDropdownSelect !== 'ranByDay'){
                this.setState({
                    formattedRuleDate : null, showLoader: false
                })
            }
            if(this.state.claimsDropdownSelect === 'notRan' && this.state.searchValue){
                return API.get('searchByFieldsInRP', {params: {[this.state.searchKey ? this.state.searchKey : 'primaryInsurance']: this.state.searchValue}}).then((response) => {
                    let filteredEncounters = response.data.data;
                    let encounterLength = response.data.length;
                    this.setState({encounters: filteredEncounters, encounterLength: encounterLength, ruleSuccess: false});
                }).catch((err) => 
                {
                    NotificationManager.error('No records found !', 'Failure!', 3000);
                    this.setState({ ruleSuccess: false})
                })
            } else if(this.state.claimsDropdownSelect === 'notRan'){
                this.setState({ ruleSuccess: false});
                return this.getEncounters(this.state.practiceId1);
            }

            let claimField = this.setClaimDropdown(this.state.claimsDropdownSelect);
            if(claimField){
                this.setState({rulesData: [], trizettoData: [], showLoader: true});
                API.get('getRunRules?'+ claimField, { 
                        params: {[this.state.searchKey ? this.state.searchKey : 'primaryInsurance']: this.state.searchValue,
                        offset: this.state.startClaimsLimit, limit: this.state.endClaimsLimit
                    }
                    }).then((response) => {
                    let filteredData = response.data;
                    let rulesData = filteredData.chargeDetail;
                    let trizettoData = filteredData.eligibilityDetail;
                    let ruleTrizettoData = filteredData.runRuleDetail;
           
                    ruleTrizettoData.sort(function(a, b){
                        return new Date(b.currentTime) - new Date(a.currentTime);
                    })
                    this.setState({
                        rulesData: rulesData ? rulesData : [], 
                        trizettoData: ruleTrizettoData,
                        eligibilityData: trizettoData,
                        ruleSuccess: response.data.success,
                        totalRows: filteredData.count_row, 
                        showLoader: false,
                        count_encounters: filteredData.count_encounters
                    });
                }).catch(err => {
                  console.error(err);
                })
            }
            
        } else {
            this.getEncounters(this.state.practiceId1)
        }
    }
    }, 1000);

    setClaimDropdown = (claimValue) => {
        switch(claimValue) {
            case 'claimsNotReviewed' : 
                return 'isReviewed=false'
            case 'claimsReviewed' : 
                return 'isReviewed=true'
            case 'ranByDay' :
                const dateSelect = this.state.formattedRuleDate;
                if(!dateSelect){
                    NotificationManager.error('Add a date to use this selected option', 'Failed!', 3000)
                    return;
                }
                return 'date='+moment(this.state.formattedRuleDate).format('YYYY-MM-DD')
            case 'notRan' : 
                return ;
            default: 
                return;
        }
    }

    // API to get last created rule
    getLastRule = () => {
        API.get('getLastRule').then((response) => {
            const lastRule = response.data.data;
            this.setState({ getLastRule: lastRule });
        }).catch(err => {
            console.error(err);
        })
    }

    // API to get draft list from practice
    getDraftList = (practiceId) => {
        API.get(`getChargesFromLocal/${practiceId}/Draft`, {params: {}}).then((response) => {
            const draftList = response.data.result;
            this.setState({ encounters: [] });
            this.setState({ encounters: draftList })
        }).catch(err => {
            console.error(err);
        })
    }

    // API to get rejected list from practice
    getRejectedList = () => {
        API.get('getChargesFromLocal/Rejected').then((response) => {
            const rejectedList = response.data.result;
            this.setState({ encounters: rejectedList });
        }).catch(err => {
            console.error(err);
        })
    }


    // handle run rules based on provider status
    handleRunRules = () => {
        let {encounters, selectedValues} = this.state;
        let notActiveDataArray = [];
        const encounterData = encounters.filter((item) => selectedValues.includes(item.EncounterID));
        notActiveDataArray = encounterData.filter(item => item.RenderingProviderStatus === false);
        // console.log('Rules Ran', notActiveDataArray);
        if(client?.defaultChargePerProvider === true && notActiveDataArray.length > 0){
            this.setState({showProviderPopUp: true});
        }else{
            this.runRules();
        }
    }
    // API to run eligibility
    runRules = () => {
        let {encounters, selectedValues} = this.state;
        this.setState({showProviderPopUp: false});
        if(selectedValues.length > 0){
            let activeDataArray = [];
            const encounterData = encounters.filter((item) => selectedValues.includes(item.EncounterID));

            activeDataArray = encounterData.filter(item => item.RenderingProviderStatus === true);
            // console.log('Rules Ran', activeDataArray);
            
            let dataArray = []; let encounterIds = []; let providerStatus;
    
            let practiceData  = JSON.parse(sessionStorage.getItem("practice"));
            let client = JSON.parse(sessionStorage.getItem("clientDetail"));
            
            (client.defaultChargePerProvider === true ? activeDataArray : encounterData).forEach((data, index) => {    
                // code to get first & last name of provider
                let providerName = data.SchedulingProviderName;
                let providerArr = [];
                let x = providerName.split(",")[0];
                let z = x.split(" ")
                z.pop()
                for (let i=0; i < z.length+1; i++){
                    let y = x.split(" ")[i]
                    providerArr.push(y)
                }
                let providerFirstName = providerArr[0];
                let providerLastName = providerArr[z.length]
                let patientFirstName = data.PatientFirstName;
                let patientLastName = data.PatientLastName;
                
                // code to get date of birth in the required format
                function DateConverter(dateOfBirth){
                    if(dateOfBirth !== ""){
                        let dob = dateOfBirth;
                        let x2 = dob.split("/");
                        let patientDob = x2[2] + x2[0] + x2[1]
                        return patientDob;
                    }else{
                        return dateOfBirth;
                    }
                }
    
                let serviceDate = data.ServiceStartDate;
                var dateOfService = serviceDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3$1$2");
    
                let {token} = JSON.parse(sessionStorage.getItem("token"));
                providerStatus = data.RenderingProviderStatus
    
                let body_data = {
                    GediPayerID: data.payerId,
                    EncounterID: data.EncounterID,
                    PatientID: data.PatientID,
                    isDuplicate: false,
                    ProviderFirstName: "",
                    ProviderLastName: practiceData.practiceName,
                    // NPI: practiceData.groupNPI,
                    NPI: '1558427526',
                    InsuranceNum: data.PrimaryInsurancePolicyNumber,
                    InsuredFirstName: patientFirstName,
                    InsuredLastName: patientLastName, // condition to be applied (patient relation to insured)
                    InsuredDob: DateConverter(data.PatientDateOfBirth), // condition to be applied (patient relation to insured)
                    InsuredGender: data.Gender, // condition to be applied (patient relation to insured)
                    // SubscriberFirstName: data.PrimaryInsurancePolicyPatientRelationshipToInsured === 'S' ? patientFirstName : patientFirstName,
                    // SubscriberLastName: data.PrimaryInsurancePolicyPatientRelationshipToInsured === 'S' ? patientLastName : patientLastName,
                    // SubscriberDob: data.PrimaryInsurancePolicyPatientRelationshipToInsured === 'S' ? DateConverter(data.PatientDateOfBirth) : DateConverter(data.PatientDateOfBirth),
                    // // SubscriberDob: data.PrimaryInsurancePolicyPatientRelationshipToInsured === 'S' ? patientDob : DateConverter(data.PrimaryInsurancePolicyInsuredDateOfBirth),
                    // SubscriberGender:  data.PrimaryInsurancePolicyPatientRelationshipToInsured === 'S' ? data.Gender : data.Gender,
                    // SubscriberGender:  data.PrimaryInsurancePolicyPatientRelationshipToInsured === 'S' ? data.Gender : data.PrimaryInsurancePolicyInsuredGender,
                    DependentFirstName: patientFirstName,
                    DependentLastName: patientLastName,
                    DependentDob: DateConverter(data.PatientDateOfBirth),
                    DependentGender: data.Gender,
                    DateOfService: dateOfService,
                    userToken: token,
                    renderingProviderStatus: providerStatus
                }
                // console.log(dateOfService);
                // if(client.defaultChargePerProvider === true) {
                //     if(body_data.renderingProviderStatus === true){
                //         dataArray.push(body_data)
                //     }else{
                //         notActiveDataArray.push(body_data);
                //     }
                // }else{
                //     dataArray.push(body_data);
                // }
                // console.log(dataArray, notActiveDataArray);
                dataArray.push(body_data);
                encounterIds.push(data.EncounterID);
            })
            // if(notActiveDataArray.length > 0 && this.setState({showProviderPopUp: true}));
            if(dataArray.length > 0) {
                this.setState({showPopup: !this.state.showPopup});
                rulesTimer = setTimeout(() => this.setState({showCancelPopup: !this.state.showCancelPopup}), 15000)
                API.post('trizetto/DoInquiry_KareoUpdateEncounterStatus', dataArray, {
                    headers: {
                        CustomerKey: practiceData.key,
                        User: practiceData.email,
                        Password: practiceData.password,
                        PracticeName: practiceData.practiceName
                    }
                }).then((response) => {
                    clearTimeout(rulesTimer);
                    // clearTimeout(rulesTimer2);
                    let newEncounters = encounters.filter(item => !encounterIds.includes(item.EncounterID));
                    // console.log(newEncounters);
                    NotificationManager.success('Rules ran successfully!', 'Successful!', 3000);
                    // console.log(response.data.result);
                    let trizettoData = response.data.result;
                    let length = trizettoData.length;
                    this.setState({
                        rulesRan: true, 
                        encounterData: encounterData,
                        newEncounters: newEncounters,
                        showPopup: false,
                        ruleDate: trizettoData[length-1].currentDate,
                        trizettoSuccess: response.data.success,
                        claimsDropdownSelect: 'ranByDay',
                        selectedValues: []
                    }, () => {
                        this.handleDateChange(new Date(), '')
                        this.searchEncounters();
                    });
                }).catch(err => {
                    NotificationManager.error('There was an error while running the rules', 'Error!', 5000);
                    this.setState({showRulesTryAgain: true, showPopup: false});
                    console.error(err);
                })
            }
        }else {
            NotificationManager.error('Please select atleast one encounter to run the rules', 'Error!', 5000);
        }
    }

    handleCheckboxChange = (event, formKey) => {
        const { name, checked } = event.target;
        const updatedCheckedItems = { ...this.state.checkedItems, [name]: checked };
        let checkedCount = Object.values(updatedCheckedItems).filter((value) => value).length;
    
        this.setState({
          checkedItems: updatedCheckedItems,
          count: checkedCount,
          message: checkedCount > 29 ? NotificationManager.info('30 encounters have alread been selected', 'Message!', 3000) : null
        });
    }

      getCacheData = async (cache_name, key) => {

        var names = await caches.keys()
        const cacheStorage = await caches.open(cache_name);
        
        const cachedResponse = await cacheStorage.match(key);
        if(cachedResponse) {
            return await cachedResponse.json()
        } else {
            return false
        }
    }

    deleteSpecificCache = (cacheName) => {
        if ("caches" in window) {
            caches.delete(cacheName).then(function (res) {
                return res;
            });
        }
    }

    // function to update auto run rule in practice
    updatePractice = (autoRunRules) => {
        return new Promise((resolve, reject) => {const data = {
                autoRunRules: autoRunRules,
            }
            API.put(`updatePractice/${this.state.practiceId1}`, data).then(({data}) => {
                sessionStorage.setItem(this.SESSION_PRACTICE_KEY, JSON.stringify(data.data))
                this.setState({
                    autoRunRuleChecked: autoRunRules
                })
                this.state.refreshPractice()
                resolve(true)
                NotificationManager.success('Auto-run Rules has been updated successfully!', 'Successful!', 3000);
            }).catch(err => {
                NotificationManager.error('An error occured while updating the Auto-run Rules', 'Failed!', 3000);
            })
        })
    }

    // function to edit last rule
    editLastRule = (id) => {
        let ruleDetail = this.state.getLastRule;
        let length = ruleDetail[0].ruleInfo.length;
        let obj = {
            for: ruleDetail[0].ruleInfo[length - 1].operator,
            pendValue: ruleDetail[0].result,
            reason: ruleDetail[0].Reason,
            operator: "none"
        }
        ruleDetail[0].ruleInfo.push(obj);
        this.props.history.push({
            pathname: `/edit-rule/${id}`,
            state: {
                editRule: true,
                ruleDetail: ruleDetail[0],
                tags: ruleDetail[0].tags,
                ruleId: id,
                path: `/edit-rule/${id}`
            }
        });
    }

    // function to handle provider pop up
    handleProviderPopup = () => {
        this.setState({showProviderPopUp: !this.state.showProviderPopUp, selectedValues: []});
    }

    async componentDidMount() {
        this.getLastRule();
        this.handlePMConnection()

        localStorage.setItem("refreshState",false);
        localStorage.setItem("isPrimary",false);


        const oldPageDetail = await this.getCacheData('navigateBack', 'goReverse')
        let autoRunRuleValue = JSON.parse(sessionStorage.getItem("practice"))
        
        if(socket) {
            socket.emit("checkAutoRun", {
                practiceId: autoRunRuleValue?.id,
            });

            // socket.emit("trackPracticeStatus", {

            // })

            // socket.on("trackPracticeStatus", (data) => {
            //     console.log('trackPracticeStatus ', data)
            // })
            let clientKey = await getClientKey();

            socket.on("checkAutoRun", (data) => {
                if(clientKey === data.clientKey) {
                    if(autoRunRuleValue.id === data.data.id){
                        if(data.status === true) {
                            this.setState({enableAutoRunRuleSetting:true})
                        } else {
                            this.setState({enableAutoRunRuleSetting:false})
                        }
                    }
                }
            });
            socket.on('disconnect', () => {
                this.setState({enableAutoRunRuleSetting:false})
            })
        }

        if(oldPageDetail.goReverse){
            let value = claimsDropdown.filter(item => {
                return item.key === oldPageDetail.claimsDropdownSelect
            })
            this.setState({
                startClaimsLimit: oldPageDetail.startClaimsLimit,
                startClaimsLimit: oldPageDetail.startClaimsLimit,
                page: oldPageDetail.page,
                searchValue: oldPageDetail.search_value ,
                searchKey: oldPageDetail.searchKey ,
                claimsDropdownSelect: oldPageDetail.claimsDropdownSelect,
                labelValue: value.length ? value[0].value : '',
                formattedRuleDate : oldPageDetail.formattedRuleDate ? new Date(oldPageDetail.formattedRuleDate) : ''
            }, () => {
                this.deleteSpecificCache('navigateBack')
                this.searchEncounters()
            })
        } else {
            console.log('oldPageDetail.goReverse else condition')
            this.setState({
                claimsDropdownSelect: 'notRan'
            })
        }
        if(this.props.history.location.state) {
            this.state.handlePracticeChangeByNotReviewPage({
                target: { 
                    value: this.props.history.location.state.practiceId
                }, 
                practiceData: this.props.history.location.state.practiceData,
                }, () => {
            })
            this.setState({
                claimsDropdownSelect: "claimsNotReviewed"
            }, () => {
                this.searchEncounters()
            })
        }
    }

    render() {
        const { encounters, showRule, selectedValues, getLastRule, trizettoData, fromDate, toDate, filterDateType, fromDate1, toDate1, encounterCount,
            encounterData, sortData, rulesData, ruleSuccess, showChargesPopup, selectDateType, selectedColumn, encounterLength, eligibilityData, showCancelLoader,
            showRunningCharges, showCancelRunningCharges, showChargesTryAgain, showPopup, showCancelPopup, showRulesTryAgain, showLoader, providerType, showProviderPopUp } = this.state;
        
        let practiceData  = JSON.parse(sessionStorage.getItem("practice"));
        let client = JSON.parse(sessionStorage.getItem("clientDetail"));

        let pmLinkedStatus = JSON.parse(sessionStorage.getItem('practice'))?.PMLinkedStatus;
        const rule = getLastRule[0];
        const showStyle = {
            width: 40,
            height: 60,
            backgroundColor: '#5CB02C',
            lineHeight: '12px',
            cursor: 'pointer',
            position: 'fixed',
            top: 100,
            right: 0,
            zIndex:3,
            borderTopLeftRadius: '9px',
            borderBottomLeftRadius: '9px'
        }
        const hideStyle = {
            width: 300,  
            height:'100%',
            overflow:'hidden',
            background: 'linear-gradient(196.38deg, #81C3D7 13.47%, #81C3D7 38.72%, #3A7CA5 92.18%)',
            border: '1px solid #878787',
            borderRadius:'9px',
            lineHeight: '12px',
            position: 'fixed',
            top: 100,
            right: 1,
            zIndex:0,
            transition:'all .3s ease'
        }
        const header = {
            backgroundColor: '#24476A',
            height: 110,
            width: '100%',
            marginLeft: 0,
            borderRadius: '9px 9px 0px 0px'
        }     
        const styles = {
            content: {
                width: '94%',
                marginTop: !pmLinkedStatus ? '' : 118,
                marginLeft: 88,
                marginBottom: 80,
                marginTop:'7rem',
                padding:'0rem 1rem 1rem 1rem',
                border:'0px !important',
                position: 'absolute'
            }
        }
        return (
            <section>
                <SideBar 
                refreshPractice={(method) => {
                    this.setState({refreshPractice: method})
                }}
                handlePracticeChangeByNotReviewPage = {(method) => this.setState({handlePracticeChangeByNotReviewPage: method})}
                isNavigateFromDashborad = {this.props.history.location?.state?.practiceId}
                handleChangePractice={(selectedPractice) => {
                    this.handleAutoRunStatus(selectedPractice.id)
                    this.getEncounters(selectedPractice.id)
                    this.handlePMConnection()
                    this.setState({
                      encounters: [],
                      encounterCount: 0,
                      practiceId1: selectedPractice.id,
                      autoRunRuleChecked: getDataFromSession(
                        this.SESSION_PRACTICE_KEY
                      )?.autoRunRules,
                      searchKey: "",
                      claimsDropdownSelect: "",
                      formattedRuleDate: "",
                      startClaimsLimit: 0,
                      page: 0,
                    });
                }}
                totalClaims={this.state.rulesRan ? encounterData : encounters}
                totalFilteredClaims={rulesData}
                ruleSuccess={this.state.ruleSuccess}
                formattedRuleDate={this.state.formattedRuleDate}
                date={this.state.date}
                localDate={this.state.localDate}
                onRunRules={() => this.runRules()}
                rulesRan={this.state.rulesRan}
                getEncounters={this.getEncounters} 
                chargesFunc={(practiceId) => this.getCharges()}
                getAutoRunRulesStatus={this.getAutoRunRulesStatus}
                handleRuleProcesserButton={this.handleRuleProcesserButton}
                />

                {pmLinkedStatus === false && <div className='connection-banner'>
                    <p className='mt-3'>WE ARE HAVING TROUBLE CONNECTING TO YOUR THIRD PARTY SOFTWARE. PLEASE CHECK THE CONNECTION IN THE SETTINGS</p>
                </div>}
             
                <div style={styles.content}>
                    <div className={showRule ? 'pt-4 table-responsive custom-size tableresponsivecustom opentoggle1' : 'pt-4 table-responsive custom-size tableresponsivecustom'}>
                       {} <table className="rule-table">
                            <thead>
                                <tr>
                                    <th className="first-header"></th>
                                    {columnHeader.map((col, index) => (
                                        <th className='text-center' key={index} >
                                            <span style={{color: selectedColumn === index ? '#5CB02C' : '#FFFFFF'}} onClick={(id) => this.colorElement(index)}>
                                                <span
                                                type="button" 
                                                className='font-face-futura-thead'
                                                onClick={e => sortData ? this.onSort(e, col.key, 'desc', ruleSuccess) : this.onSort(e, col.key, 'asc', ruleSuccess)}>
                                                    {(providerType === 'Scheduling' && col.name === 'Provider') ? 'Scheduling Provider' : 
                                                    (providerType === 'Rendering' && col.name === 'Provider') ? 'Rendering Provider' : col.name}
                                                </span>
                                            </span>
                                        </th>
                                    ))}
                                    {ruleSuccess === true ? 
                                    <th className="text-center">
                                        <span type="button" className='mr-50 font-face-futura-thead'>Rules Ran On</span></th> : <></>}
                                </tr>
                            </thead><br />
                            {!this.state.enableAutoRunRuleSetting && <tbody>
                                {ruleSuccess !== true ? encounters.length > 0 ? encounters.map((encounter, index) => {
                                    let patientName = encounter.PatientName;
                                    patientName = patientName.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let SproviderName = encounter?.SchedulingProviderName;
                                    SproviderName = SproviderName?.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let RproviderName = encounter?.RenderingProviderName;
                                    RproviderName = RproviderName?.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let primary = encounter.PrimaryInsurancePlanName;
                                    primary = primary.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let secondary = encounter.SecondaryInsurancePlanName;
                                    secondary = secondary.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let location = encounter.ServiceLocationName;
                                    location = location.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    return(
                                    <tr style={{ cursor: 'pointer'}} key={index} className={selectedValues.includes(encounter.EncounterID) ? "table-row tabl-with-border" : "table-row" }>
                                        <td>
                                            <div className='innerdata' style={{ marginLeft:'1rem', borderRadius:'9px 0 0 9px' }}>
                                                <RoCheckbox
                                                style={{ width: 18, height: 18 }}
                                                value={encounter.EncounterID}
                                                checked={selectedValues.includes(encounter.EncounterID)}
                                                onChange={this.handleSingleSelect}
                                                disabled={!selectedValues.includes(encounter.EncounterID) && selectedValues.length > 29}
                                                />
                                            </div>
                                        </td>
                                        <td style={{textAlign:'center'}} onClick={(id) => this.handleRow(encounter.EncounterID)}>
                                            <div className='innerdata'>
                                                <span title={encounter.PatientName} className='one-line-ellipsis'>{patientName}</span>
                                            </div>
                                        </td>
                                        <td style={{textAlign:'center'}} onClick={(id) => this.handleRow(encounter.EncounterID)}>
                                            <div className='innerdata'>{encounter.EncounterID}</div>
                                        </td>
                                        <td style={{textAlign:'center'}} onClick={(id) => this.handleRow(encounter.EncounterID)}>
                                            <div className='innerdata'>
                                                <div> 
                                                    <p title={providerType === 'Scheduling' ? encounter?.SchedulingProviderName : encounter?.RenderingProviderName} 
                                                        className='one-line-ellipsis'>
                                                        {providerType === 'Scheduling' ? SproviderName : RproviderName}
                                                    </p>
                                                    <p style={{textDecoration: 'none', color: (encounter.RenderingProviderStatus === false || encounter.RenderingProviderStatus === null) && '#954437'}}>
                                                        {(encounter.RenderingProviderStatus === false || encounter.RenderingProviderStatus === null) && 'Rendering Not Active'}
                                                    </p> 
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{textAlign:'center'}} onClick={(id) => this.handleRow(encounter.EncounterID)}>
                                            <div className='innerdata'>{encounter.ServiceStartDate}</div>
                                        </td>
                                        <td style={{textAlign:'center'}} onClick={(id) => this.handleRow(encounter.EncounterID)}>
                                            <div className='innerdata'>{encounter.ProcedureCode}</div>
                                        </td>
                                        <td style={{textAlign:'center'}} onClick={(id) => {this.handleCell(encounter.EncounterID, encounter.PrimaryInsuranceLinkedStatus);localStorage.setItem("isPrimary",true)}}>
                                            <div className='innerdata'>
                                                <div>
                                                    <p title={encounter.PrimaryInsurancePlanName} className='dblock one-line-ellipsis'>{primary}</p>
                                                    <p style={{textDecoration: 'none', color: encounter.PrimaryInsuranceLinkedStatus === true ? '#5CB02C' : '#954437'}}>
                                                        {encounter.PrimaryInsuranceLinkedStatus === true ? 'EDI Linked' : encounter.PrimaryInsurancePlanName ? 'EDI Not Linked' : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{textAlign:'center'}}>
                                            <div className='innerdata'>
                                                <div>
                                                    <p title={encounter.SecondaryInsurancePlanName} className='one-line-ellipsis'>{secondary}</p>
                                                </div>
                                            </div>
                                        </td>

                                        <td style={{textAlign:'center'}} onClick={(id) => this.handleRow(encounter.EncounterID)}>
                                            <div className='innerdata'>
                                                <span title={encounter.ServiceLocationName} className='one-line-ellipsis'>{location}</span>
                                            </div>
                                        </td>
                                        <td style={{textAlign:'center'}} onClick={(id) => this.handleRow(encounter.EncounterID)}>
                                            <div className='innerdata' style={{ marginRight:'0rem', borderRadius:'0px 9px 9px 0px' }}>{encounter.EncounterDiagnosisID1}</div>
                                        </td>
                                    </tr>
                                )}) : (<tr><td colSpan={6} className="mt-3 noRecord text-center">No records found!</td></tr>) : 
                                rulesData.length > 0 ? rulesData.map((rules, idx) => {
                                    let patientName = rules.PatientName;
                                    patientName = patientName.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let providerName = client.providerType === 'Scheduling' ? rules.SchedulingProviderName : rules.RenderingProviderName;
                                    providerName = providerName.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let primary = rules.PrimaryInsurancePlanName;
                                    primary = primary.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let secondary = rules.SecondaryInsurancePlanName;
                                    secondary = secondary.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let location = rules.ServiceLocationName;
                                    location = location.toLowerCase().replace(/\b[a-z]/g, function(letter) {
                                        return letter.toUpperCase();
                                    });
                                    let runEncounterTime = new Date(rules?.currentTime || null).toLocaleString('en-US')
                                    let runEncounterTimeObj = new Date(runEncounterTime);
                                    runEncounterTime = runEncounterTimeObj.toLocaleString('en-US')
                                    return(
                                    <tr style={{ cursor: 'pointer' }} key={idx} className={rules?.isReviewed ? "table-row tbl-with-border" : "table-row" }>
                                        <td className="text-center">
                                            <div className='innerdata' style={{ marginLeft:'1rem', borderRadius:'9px 0 0 9px' }}>
                                                <IconButton>
                                                    
                                                    {rules?.runEligibilityForPrimary === true ? 
                                                        <>{rules?.isPassed === true ? 
                                                            <CheckCircleIcon fontSize="large" style={{color: '#5CB02C'}} /> : 
                                                            <CancelIcon fontSize="large" style={{color: '#954437'}} />}
                                                        </> : 
                                                        <>{rules?.isPassed === true ? 
                                                            <CheckCircleIcon fontSize="large" style={{color: '#5CB02C'}} /> : 
                                                            <CancelIcon fontSize="large" style={{color: '#954437'}} />}
                                                        </>
                                                    }
                                                </IconButton>
                                            </div>
                                        </td>
                                        <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID, rules.EncounterProcedureID)}>
                                            <div className='innerdata'>
                                                <span title={rules.PatientName} className='one-line-ellipsis'>{patientName}</span>
                                            </div>
                                        </td>
                                        <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID, rules.EncounterProcedureID)}><div className='innerdata'>{rules.EncounterID}</div></td>
                                        <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID, rules.EncounterProcedureID)}>
                                            <div className='innerdata'>
                                                <span title={client.providerType === 'Scheduling' ? rules.SchedulingProviderName : rules.RenderingProviderName} 
                                                className='one-line-ellipsis'>{providerName}</span>
                                            </div>
                                        </td>
                                        <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID, rules.EncounterProcedureID)}><div className='innerdata'>{rules.ServiceStartDate}</div></td>
                                        <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID, rules.EncounterProcedureID)}><div className='innerdata'>{rules.ProcedureCode}</div></td>
                                        <td className="text-center" onClick={(id) => {this.handleCell(rules.instanceID);localStorage.setItem("isPrimary",true)}}>
                                            <div className='innerdata'>
                                                <div>
                                                    <p title={rules.PrimaryInsurancePlanName} className='dblock one-line-ellipsis'>{primary}</p>
                                                    <p style={{textDecoration: 'none', color: rules?.eligibilitySuccess === true ? '#5CB02C' : '#954437'}}>
                                                        {rules.PrimaryInsurancePlanName && rules?.eligibilitySuccess === true ? 'Active' : 
                                                        (rules.PrimaryInsurancePlanName && rules?.eligibilitySuccess === false && rules?.PrimaryInsuranceLinkedStatus === true && rules?.isBypassed) ? 'Bypassed' : 
                                                        (rules.PrimaryInsurancePlanName && rules?.eligibilitySuccess === false && rules?.PrimaryInsuranceLinkedStatus === true && rules?.PrimaryInsurancePolicyNumber !== "") ? 'Not Active' : 'N/A' }
                                                    </p>
                                                </div>
                                            </div>
                                        </td>

                                        <td className="text-center">
                                            <div className='innerdata'>
                                                <div>
                                                    <p title={rules.SecondaryInsurancePlanName} className='one-line-ellipsis'>{secondary}</p>
                                                </div>
                                            </div>
                                        </td>

                                        <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID, rules.EncounterProcedureID)}>
                                            <div className='innerdata'>
                                                <span title={rules.ServiceLocationName} className='one-line-ellipsis'>{location}</span>
                                            </div>
                                        </td>
                                        <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID, rules.EncounterProcedureID)}><div className='innerdata'>{rules.EncounterDiagnosisID1}</div></td>
                                        <td className="text-center" onClick={(id) => this.handleRow(rules.instanceID, rules.EncounterProcedureID)}>
                                            <div className='innerdata' style={{ borderRadius:'0 9px 9px 0', marginRight: '-10px' }}>
                                                <span title={runEncounterTime} className='one-line-ellipsis'>{runEncounterTime}</span>
                                            </div>
                                        </td>
                                    </tr>
                                )}) : (<tr><td colSpan={6} className="mt-3 noRecord text-center">No records found!</td></tr>)}
                            </tbody>}
                            {this.state.enableAutoRunRuleSetting && 
                                 <tbody>
                                 <tr style={{height: "100%"}}>
                                     <td>
                                         "Auto-Run is currently in progress..."
                                     </td>
                                 </tr>
                             </tbody>
                            }
                           
                        </table>
                        
                        <div className={showRule ? 'rule-footer opentoggle2' : 'rule-footer'}>
                            <div className='foot-flex'>
                                <div className='foot-left'>
                                    {ruleSuccess ? 
                                    <div className='encounter_count'>
                                        <span>Total Encounters: {this.state?.count_encounters}</span>
                                    </div> :
                                    <>
                                        <Button 
                                        variant="contained"
                                        disabled={pmLinkedStatus === false ? true : false}
                                        className="text-white p-2 text-capitalize runSelected" 
                                        onClick={() => client?.defaultChargePerProvider === true ? this.handleRunRules() : this.runRules()}>
                                            <span className='font-face-futura' style={{fontSize: 14}}>Run Selected</span>
                                        </Button>

                                        <div class="round-check">
                                            <input 
                                                type="checkbox" 
                                                id="check-round" 
                                                disabled={pmLinkedStatus === false ? true : false}
                                                checked={this.state.autoRunRuleChecked === true && JSON.parse(sessionStorage.getItem('practice'))?.PMLinkedStatus === true ? true : false} 
                                                onClick={async () => await this.updatePractice(!this.state.autoRunRuleChecked)} />
                                            <label for="check-round"></label>
                                            <span className='text-dark font-face-futura dlock'>Auto-run Rules</span>
                                        </div>
                                    </>}

                                    
                                </div>
                                <div className='foot-right'>
                                    <div style={{marginLeft: ruleSuccess ? '0px' : '110px'}}>
                                        {ruleSuccess ? <></> : <div> 
                                        {localStorage.getItem("refreshState") === true ? 
                                        <p className="text-dark">Refreshing...</p> : 
                                        <a 
                                        style={{textDecoration: 'none', cursor: 'pointer'}} 
                                        onClick={() => this.OpenChargesPopup()}>
                                        <img src={refresh} alt="logo" width={45} style={{height: 50, float: 'left', marginRight:'20px', marginTop: 10}} /></a>
                                        }
                                        </div>}
                                        {ruleSuccess ? 
                                        <div>
                                            <TablePagination
                                                rowsPerPageOptions={this.state.pages}
                                                component="div"
                                                count={this.state.totalRows}
                                                rowsPerPage={this.state.rowsPerPage}
                                                page={this.state.page}
                                                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                                                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                                                onPageChange={this.handlePaginationData}
                                                onRowsPerPageChange={(e) => this.onRowsPerPage(e)}
                                            />
                                        </div> :
                                        <span className="text-dark font-face-futura">
                                            <span className='fontw'>
                                            Total Claims: {this.state.rulesRan ? encounterData.length : this.state.searchValue === '' ? encounterCount : encounterLength} </span><br />
                                            <span className='dateFont'>{practiceData?.filterDateType === 'createdDate' ? 'Created' : 'Serviced'}{' '}
                                                <b>From:</b> {practiceData?.fromDate}{' '} 
                                                <b>To:</b> {practiceData?.toDate}
                                            </span><br />
                                            <span className='dateFont'>Last Updated: {new Date(practiceData?.lastUpdatedDate).toLocaleString('en-US')}</span>
                                        </span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Box>
                            {showRule ? (
                                    <div style={hideStyle}>
                                        <header className="row text-white font-face-futura" style={header}>
                                            <div className="col-10 mt-2">
                                                <h5 className="mostR">MOST<br />RECENT RULE</h5>
                                                <small className='small-label font-face-futura'>See what you created with us last to help test it with your current work list</small>
                                            </div>                                         
                                            
                                            <div className="col-2" style={{ cursor: 'pointer' }} onClick={this.handleHide}>
                                                <RemoveRedEyeIcon className="mt-2" /><br />
                                                <span style={{ fontSize: 12 }}>Hide</span>
                                            </div>
                                        </header>
                                        <div className="font-face-futura ruleStyle">
                                            <h6 className="m-1" style={{ fontSize: 18 }}>Rule Name
                                                <div className="mt-1" style={{ width: 85, borderBottom: '1px solid #0080A8' }}></div></h6>
                                            <p className="mt-2 mx-1 mb-2" style={{ fontSize: 14 }}>{rule?.ruleName}</p>
                                            <div className="bottomDivider"></div>

                                            <div className='rule-box1'>
                                                <h6 className="m-1" style={{ fontSize: 18 }}>Rule
                                                <div className="mt-1" style={{ width: 32, borderBottom: '1px solid #0080A8' }}></div></h6>
                                                <p className="mt-2 mx-1" style={{ fontSize: 14, lineHeight: 1.5 }}>
                                                    <span>{rule ? 'When the' : ''}</span> {' '}
                                                    {rule?.ruleInfo.map((item, idx) => {
                                                    let finalValues = (item.condition === 'is' || item.condition === 'contains') ? (item.values.map(val => val.name ? val.name : val)).join(' or ') : (item.values.map(val => val.name ? val.name : val)).join(' and ');
                                                    return <span>{(idx > 0 ? ' the ' + item.category : item.category) + ' ' + item.condition + ' ' + finalValues + ' ' + (item.operator).toLowerCase()}
                                                    </span>})} {rule?.result}<span>{rule ? '.' : ''}</span>
                                                </p>
                                                <div className="bottomDivider"></div>

                                                <h6 className="m-1" style={{ fontSize: 18 }}>Reason
                                                    <div className="mt-1" style={{ width: 55, borderBottom: '1px solid #0080A8' }}></div></h6>
                                                <p className="mt-2 mx-1" style={{ fontSize: 14 }}>{rule?.Reason}<span>{rule ? '.' : ''}</span></p>
                                                <div className="bottomDivider"></div>
                                            </div>

                                            <h6 className="m-1" style={{ fontSize: 18 }}>Info
                                                <div className="mt-1" style={{ width: 28, borderBottom: '1px solid #0080A8' }}></div></h6>
                                            <p className="mt-2 mx-1 font-face-futura" style={{ fontSize: 14, lineHeight:'1rem' }}><strong>Tags:</strong> {rule?.tags.length > 0 && (rule.tags).join(', ')}</p>
                                            <p className="mt-2 mx-1 font-face-futura" style={{ fontSize: 14, lineHeight:'1rem' }}><strong>Created On:</strong> {(new Date(rule?.created_at)).toLocaleDateString()}</p>
                                            <p className="mt-2 mx-1 font-face-futura" style={{ fontSize: 14, lineHeight:'1rem' }}><strong>Created By:</strong> Loren Dilger</p>
                                            <div className="bottomDivider"></div>

                                            <div align="center" className="mrtop-55">
                                                <Button className="text-white ruleBtn" variant="contained" onClick={(id) => this.editLastRule(rule?.ruleId)}>
                                                    <span className='font-face-futura'>Edit</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-white" style={showStyle} onClick={this.handleShow}>
                                        <VisibilityOffIcon className="mt-1" /><br />
                                        <span className='font-face-futura' style={{ fontSize: 12 }}>Last <br /> Rule</span>
                                    </div>
                                )}
                        </Box>
                    </div>
                </div>

                {showChargesPopup && 
                <div>
                 <Dialog 
                 className="claims-modal claims-modal-custom"
                fullWidth
                maxWidth="md"
                open={showChargesPopup} 
                onClose={this.closeChargesPopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" >
                    <p className='chargesTitle font-face-futura'><span className='mx-2'>{"REFRESH ENCOUNTERS"}</span>
                        <p style={{ borderBottom: '1px solid #7BBC9B' }}></p>
                    </p>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div className='font-face-futura'>
                                <label htmlFor='select' className='labelDialog'><b>Select Filter:</b></label>
                                <select className='mt-1 selectStyles selectDialog' 
                                value={selectDateType} 
                                onChange={this.handleChargesDropdown}>
                                    <option name="createdDate" value="createdDate">Created Date</option>
                                    <option name="serviceDate" value="serviceDate">Service Date</option>
                                </select>
                            </div>
                            <div className='mt-4 font-face-futura'>
                                <div className='row'>
                                    <div className='col-6 col-sm-6 col-lg-6'>
                                        <label htmlFor='select' className='labelDialog'><b>From:</b></label>
                                        <DatePicker className='date-pick text-dark'
                                        name='from'
                                        format={"MM-dd-yyyy"} 
                                        selected={fromDate ? fromDate : myPastDate}
                                        minDate={maxPastDate}
                                        onChange={this.handleFromDate}
                                        preventOpenOnFocus={true}
                                        customInput={
                                        <MaskedInput 
                                        pipe={autoCorrectedDatePipe}
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        guide={true}
                                        keepCharPositions={true} />
                                        } />
                                    </div>
                                    <div className='col-6 col-sm-6 col-lg-6'>
                                        <label htmlFor='select' className='labelDialog'><b>To:</b></label>
                                        <DatePicker className='date-pick text-dark' 
                                        name='to'
                                        format={"MM-dd-yyyy"} 
                                        selected={toDate ? toDate : currentToDate}
                                        minDate={maxPastDate}
                                        onChange={this.handleToDate}
                                        preventOpenOnFocus={true}
                                        customInput={
                                        <MaskedInput 
                                        pipe={autoCorrectedDatePipe}
                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                        guide={true}
                                        keepCharPositions={true} />} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='row mt-4'>
                                    <div className='col-6 col-sm-6 col-lg-6'>
                                        <Button fullWidth className='text-white closeBtn' variant='contained' onClick={this.closeChargesPopup}>
                                            <span className='font-face-futura'>Close</span>
                                        </Button>
                                    </div>
                                    <div className='col-6 col-sm-6 col-lg-6'>
                                        <Button fullWidth className='text-white refreshBtn' variant='contained' onClick={() => this.isDateValid()}>
                                            <span className='font-face-futura'>Refresh</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                </div>
                }

                {showRunningCharges && 
                <Dialog 
                fullWidth
                open={showRunningCharges} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <div align="center" className={showCancelRunningCharges ? 'avatar2' : 'avatar'}>
                        <img src={ruleoutLogo} alt="ruleout" className='ruleoutLogo' />
                        <Loader className="mt-2" type='Oval'
                        height={180}
                        width={195}
                        color="#5CB02C"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#5CB02C"
                        strokeWidth={2}
                        strokeWidthSecondary={2} />
                    </div>
                    <div align="center" className={showCancelRunningCharges ? 'dialog-box2' : 'dialog-box'}>
                        <DialogTitle id="alert-dialog-title">
                            <span className='font-face-futura dialogTitle'>{"Updating Encounters"}</span>
                        </DialogTitle>
                        {showCancelRunningCharges ? 
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <span className='dialogText'>Hold tight while we fetch a new list of claims!</span>
                            </DialogContentText><br/>
                            <DialogContentText id="alert-dialog-description">
                                <span className='dialogText'>Hmm... This is taking longer than expected.</span><br />
                                <span className='dialogText'>Give us a little bit longer</span>
                            </DialogContentText>
                            <Button fullWidth className='mt-4 dialogBtn' variant='contained' onClick={() => window.location.reload()}>
                                <span className='font-face-futura'>Cancel</span>
                            </Button>
                        </DialogContent> :
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <span className='font-face-futura dialogText'>Let's get you a new list of claims!</span>
                            </DialogContentText>
                        </DialogContent>}
                    </div>
                </Dialog>
                }
                {showChargesTryAgain && 
                <Dialog 
                fullWidth
                open={showChargesTryAgain} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <div align="center" className='avatar3'>
                        <CancelIcon className='dialogCancel' fontSize='large' />
                    </div>
                    <div align="center" className='dialog-box3'>
                        <DialogTitle id="alert-dialog-title">
                            <span className='font-face-futura dialogTitle'>{"UH, OH!"}</span>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <span className='font-face-futura dialogText'>We aren't able to fetch the claims</span>
                            </DialogContentText>
                            <Button fullWidth className='mt-4 dialogBtn' variant='contained' onClick={() => window.location.reload()}>
                                <span className='font-face-futura'>THAT'S OKAY, I'LL TRY AGAIN</span>
                            </Button>
                        </DialogContent>
                    </div>
                </Dialog>
                }

                {showPopup && 
                <Dialog 
                fullWidth
                open={showPopup} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <div align="center" className={showCancelPopup ? 'avatar2' : 'avatar'}>
                    <img src={ruleoutLogo} alt="ruleout" className='ruleoutLogo'/>
                    {/* <img src={ruleoutLogo} alt="ruleout" className={showCancelPopup ? 'ruleoutLogo m-none' : 'ruleoutLogo '}/> */}
                        <Loader className="mt-2" type='Oval'
                        height={180}
                        width={195}
                        color="#5CB02C"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#5CB02C"
                        strokeWidth={2}
                        strokeWidthSecondary={2} />
                    </div>
                    <div align="center" className={showCancelPopup ? 'dialog-box2' : 'dialog-box'}>
                        <DialogTitle id="alert-dialog-title">
                            <span className='font-face-futura dialogTitle'>{"Running Encounters"}</span>
                        </DialogTitle>
                        {showCancelPopup ? 
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <span className='dialogText'>Hold tight while we scrub these claims!</span>
                            </DialogContentText><br/>
                            <DialogContentText id="alert-dialog-description">
                                <span className='dialogText'>Hmm... This is taking longer than expected.</span><br />
                                <span className='dialogText'>Give us a little bit longer</span>
                            </DialogContentText>
                            <Button fullWidth className='mt-4 dialogBtn' variant='contained' onClick={() => window.location.reload()}>
                                <span className='font-face-futura'>Cancel</span>
                            </Button>
                        </DialogContent> : 
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <span className='font-face-futura dialogText'>Hold tight while we scrub these claims!</span>
                            </DialogContentText>
                        </DialogContent>
                        }
                    </div>
                </Dialog>
                }
                {showRulesTryAgain && 
                <Dialog 
                fullWidth
                open={showRulesTryAgain} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <div align="center" className='avatar3'>
                        <CancelIcon className='dialogCancel' fontSize='large' />
                    </div>
                    <div align="center" className='dialog-box3'>
                        <DialogTitle id="alert-dialog-title">
                            <span className='font-face-futura dialogTitle'>{"UH, OH!"}</span>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <span className='font-face-futura dialogText'>We aren't able to finish scrubbing</span>
                            </DialogContentText>
                            <Button fullWidth className='mt-4 dialogBtn' variant='contained' onClick={() => window.location.reload()}>
                                <span className='font-face-futura'>THAT'S OKAY, I'LL TRY AGAIN</span>
                            </Button>
                        </DialogContent>
                    </div>
                </Dialog>
                }

                {showLoader && 
                <Dialog 
                fullWidth
                open={showLoader} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <div align="center" className={showCancelLoader ? 'avatar2' : 'avatar'}>
                        <img src={ruleoutLogo} alt="ruleout" className='ruleoutLogo' />
                        <Loader className="mt-2" type='Oval'
                        height={180}
                        width={195}
                        color="#5CB02C"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#5CB02C"
                        strokeWidth={2}
                        strokeWidthSecondary={2} />
                    </div>
                    <div align="center" className={showCancelLoader ? 'dialog-box2' : 'dialog-box'}>
                        <DialogTitle id="alert-dialog-title">
                            <span className='font-face-futura dialogTitle'>{"Updating Encounters"}</span>
                        </DialogTitle>
                        {showCancelLoader ? 
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <span className='dialogText'>Hold tight while we fetch the list of encounters!</span>
                            </DialogContentText><br/>
                            <DialogContentText id="alert-dialog-description">
                                <span className='dialogText'>Hmm... This is taking longer than expected.</span><br />
                                <span className='dialogText'>Give us a little bit longer</span>
                            </DialogContentText>
                            <Button fullWidth className='mt-4 dialogBtn' variant='contained' onClick={() => window.location.reload()}>
                                <span className='font-face-futura'>Cancel</span>
                            </Button>
                        </DialogContent> :
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <span className='font-face-futura dialogText'>Let's get you the list of claims!</span>
                            </DialogContentText>
                        </DialogContent>}
                    </div>
                </Dialog>
                }

                {showProviderPopUp && 
                <Dialog 
                fullWidth
                open={showProviderPopUp} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <div align="center" className='avatar3'>
                        <CancelIcon className='dialogCancel' fontSize='large' />
                    </div>
                    <div align="center" className='dialog-box4'>
                        <DialogTitle id="alert-dialog-title">
                            <span className='font-face-futura dialogTitle'>{"UH, OH!"}</span>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <span className='font-face-futura dialogText'>You do not have a valid license for one or more providers you are trying to run.</span>
                            </DialogContentText><br />
                            <DialogContentText id="alert-dialog-description">
                                <span className='font-face-futura dialogText'>Have your system admin add the provider(s) in the settings to run the encounter</span>
                            </DialogContentText>
                            <Button fullWidth className='mt-4 mx-1 provBtn' variant='contained' onClick={() => this.handleProviderPopup()}>
                                <span className='font-face-futura'>THAT'S OKAY, I'LL TRY AGAIN</span>
                            </Button>
                            <Button fullWidth className='mt-4 mx-1 activeBtn' variant='contained' onClick={() => this.runRules()}>
                                <span className='font-face-futura'>Run Active Only</span>
                            </Button>
                        </DialogContent>
                    </div>
                </Dialog>
                }

                <Footer 
                ruleSuccess={this.state.ruleSuccess}
                formattedRuleDate={this.state.formattedRuleDate} 
                onSelectChange={this.handleSelectChange}
                optionSelector={this.state.optionSelector}
                selectHandler={this.selectHandler}
                onClaimChange={this.handleClaimChange}
                claimSelector={this.state.claimSelector}
                claimHandler={this.claimHandler}
                labelValue={this.state.labelValue}
                draftPlaceholder={this.state.draftPlaceholder}
                searchValue={this.state.searchValue}
                onInputChange={this.handleInputChange}
                onDateChange={(e) => this.handleDateChange(e, this.state.formattedRuleDate)}
                handleClaimsDropdown ={this.handleClaimsDropdown} 
                claimsDropdownSelect = {this.state.claimsDropdownSelect}
                />
            </section>
        )
    }
}

RuleProcessor.propTypes = {
  history: PropTypes.array.isRequired
}

export default withRouter(RuleProcessor);